<template lang="pug">
NuxtLink(:to="urlx")
    .flex.flex-1
        //- UIcon.w-7.h-7.text-teal-300(name="i-mdi-shield-lock-outline")
        img(src="https://github-production-user-asset-6210df.s3.amazonaws.com/31319237/284836243-bb4b233c-db31-4e76-a282-ac015ff63e5e.jpg" class="h-8")
</template>
<script setup lang="ts">
const route = useRoute();
const urlx = ref('')

if (route.path.includes("/admin")) {
    urlx.value = "/admin"
} else {
    urlx.value = "/"
}

</script>
