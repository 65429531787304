<template lang="pug">
nav.rnav.sticky.top-0.left-0.z-10(
    :class="{'bg-white': !cream}"
)
    .flex.justify-between.items-center(
        class="h-[55px]"
    )
        .flex.flex-1.items-center.h-full.gap-4

            //- .flex.block(class="md:hidden ms-2")
            //-     UButton.hidden(
            //-         class="md:block"
            //-         variant="ghost"
            //-         color="gray"
            //-         icon="mdi-menu"
            //-         @click="coreStore.sidebarMob = !coreStore.sidebarMobValue"
            //-     )
            .flex.flex-1.h-full.gap-3.b
                .flex.flex-1.self-center.h-full
                    .flex.flex-1.justify-start(v-if="$slots.navbar_left_default")
                        slot(name="navbar_left_default")

                    .flex.flex-1.self-center.ms-0(v-if="$slots.navbar_left" class="md:ms-3")
                        slot(name="navbar_left")

                    .flex.self-center.justify-end(v-if="$slots.navbar_right")
                        slot(name="navbar_right")
                .flex.self-center.me-4.gap-2
                    //- CTNavbarHelp
                    //- CTNavbarProfile
                    AdminDefaultNavbarMenu(v-if="$route.name.includes('admin')")
                    DefaultNavbarMenu(v-else)
</template>
<script setup lang="ts">
import { useCTStore } from "@stores/store";
import { DefaultNavbarMenu } from "#components";
const coreStore = useCTStore();

const props = defineProps({
    page: {
        type: String,
        default: "",
    },
    icon: {
        type: String,
        default: "",
    },
    color: {
        type: String,
        default: "",
    },
    cream: {
        type: Boolean,
        default: false,
    },
});
</script>
