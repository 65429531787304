<template lang="pug">
ClientOnly
    .group(
        id="zSidebar"
        :class="{\
            'rsidebar': !coreStore.sidebarSmValue, \
            'rsidebar-md': coreStore.sidebarSmValue\
        }"
    )
        .flex.h-full.flex-grow.flex-col.text-neutral-300(
            class="transition duration-1000 ease-in-out transform-gpu slide-in-from-left"
            :class="{'bg-zinc-900': $route.name.includes('admin')}"
        )
            .flex.flex-1.flex-col
                .w-full(class="h-[55px] max-h-[55px]")

                    //- +++++++++++++++++++++
                    //- logo
                    //- +++++++++++++++++++++
                    .flex.self-center.flex-1(
                        class="h-[55px] max-h-[55px]"
                        :class="{'justify-center': !coreStore.sidebarSmValue}"
                    )
                        //- CoreCodenameSmd
                        //- div(v-if="$route.name.includes('admin')")
                        //-     .p-3(v-if="coreStore.sidebarSmValue")
                        //-         //- CTLogo
                        //-         AdminDefaultLogo
                        //-     .p-3(v-else)
                        //-         //- CTLogoSm
                        //-         AdminDefaultLogoSm
                        //- div(v-else)
                        //-     .p-3(v-if="coreStore.sidebarSmValue")
                        //-         //- CTLogo
                        //-         DefaultLogo

                        .p-3(v-if="coreStore.sidebarSmValue")
                            //- CTLogo
                            DefaultLogo
                        .p-3(v-else)
                            //- CTLogoSm
                            DefaultLogoSm

                //- +++++++++++++++++++++
                .flex.flex-1.h-full
                    slot

                .flex.flex-col(
                    :class="{'justify-center': !coreStore.sidebarSmValue}"
                )

                    .flex(
                        :class="{'justify-center': !coreStore.sidebarSmValue}"
                        v-if="authStore?.meValue?.role === 'root' || authStore?.meValue?.role === 'superadmin'"
                    )
                        UButton(
                            to="/admin"
                            :label="coreStore.sidebarSmValue ? 'Administrator' : ''"
                            size="md" color="teal" variant="ghost" icon="i-material-symbols-shield-spark-outline-rounded"
                        )

                    ClientOnly
                        .flex.flex-1(
                            :class="{'justify-center': !coreStore.sidebarSmValue}"
                        )
                            UButton.text-neutral-400.text-sm(
                                v-if="coreStore.sidebarSmValue"
                                size="lg"
                                color="black"
                                variant="ghost"
                                label="Collapse sidebar"
                                icon="i-mdi-chevron-double-left"
                                @click="coreStore.sidebarSm = !coreStore.sidebarSmValue"
                            )
                            UButton.text-neutral-400(
                                v-else
                                size="lg"
                                color="black"
                                variant="ghost"
                                icon="i-mdi-chevron-double-right"
                                @click="coreStore.sidebarSm = !coreStore.sidebarSmValue"
                            )

</template>
<script setup lang="ts">
import { DefaultLogo, DefaultLogoSm } from "#components";
import { useCTStore } from "@stores/store";
const coreStore = useCTStore();
const authStore = useAuthStore();
const router = useRouter();
router.beforeEach((to, from, next) => {
    coreStore.sidebarMob = false;
    next();
});
</script>
